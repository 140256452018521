.skill {
    display: grid;
    grid-template-columns: 120px auto;
}

.skill-title {
    display: inline-block;
}

.bar {
    display: inline-block;
    position: relative;
    width: 100%;
}

.bar-bg {
    background-color: white;
    border-radius: 9999px;
    height: 0.5rem/* 8px */;
    bottom: 40%;
    position: absolute;
    width: 100%;
}

.bar-fill {
    background-color: red;
    border-radius: 9999px;
    height: 0.5rem/* 8px */;
    bottom: 40%;
    position: absolute;
}

