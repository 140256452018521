.blog-post {
    background-color: white;
    width: 60%;
    margin: 75px auto;
    border-radius: 0.5%;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content min-content auto;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        "post-title post-date"
        "post-author post-time"
        "post-text post-text";
}

.post-title { 
    grid-area: post-title;
    text-align: left;
    font-size: 2rem;
    margin: 1rem;
    color: black;

}

.post-author { 
    grid-area: post-author; 
    text-align: left;
    font-size: 1.5rem;
    margin: 1rem;
    color: black;
}

.post-date { 
    grid-area: post-date;
    text-align: right;
    font-size: 1.5rem;
    margin: 1rem;
    margin-top: 0.5rem;

}

.post-time { 
    grid-area: post-time;
    text-align: right;
    font-size: 1.5rem;
    margin: 1rem;
    margin-top: 0.5rem;

}

p .post-datetime {
    color: black;
}

.post-text { 
    grid-area: post-text;
    margin: 2rem;
    font-size: 1.1rem;
    color: black;
}

p {
    color: black;
}