.lid {
    background-color: black;
    width: 100%;
    height: 10vh;
    min-height: 5rem;
}


.lid p {
    color: white;
    font-size: 25px;
}

.error-page p {
    color: white;
    font-size: 20px;
}

.error-header p {
    color: white;
    font-size: 60px;
    margin: 50px;
}

h1 {
    color: white;
}

.error-page {
    background-color: #00A366;
    width: 100%;
    min-height: 100vh;

}