.contact-me-title {
    font-size: 40px;
}

.small-text-input {
    margin:10px;
    width: 20%;
}

.large-text-input {
    margin:10px;
    width : 40%;
    height: 20vh;
}

.button-input {
    width: 120px;
    height: 50px;
    margin-bottom: 100px;
    font-size: 20px;
}