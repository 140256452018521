.lid {
    background:
        linear-gradient(45deg,rgba(0,0,0,0.55) ,rgba(25,25,25,0.55)),
        url(static/noise.svg);
    width: 100%;
    height: 10vh;
    min-height: 5rem;
}


.lid p {
    color: white;
    font-size: 25px;
}

p {
    color: white;
}

h1 {
    color: white;
}

.underlined {
    text-decoration-line: underline;
    text-decoration-color: white;
}

.bottle {
    background: 
        linear-gradient(0deg,rgba(0,160,105,1) ,rgba(0,143,82,0.75)),
        url(static/noise.svg);
    width: 100%;
    min-height: 100vh;
    padding-bottom: 10px;
    
}

.Nav { 
    grid-area: Nav; 
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 0.25rem/* 4px */;
    justify-content: space-evenly;
    justify-items: center;
    place-items: center;
}


a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}