.lid {
    background-color: black;
    width: 100%;
    height: 10vh;
    min-height: 5rem;
}


.lid p {
    color: white;
    font-size: 25px;
}

.about-me p {
    color: white;
}

h1 {
    color: white;
}

.about-me {
    width: 100%;
    min-height: 100vh;
    
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        "Bio Bio"
        "Employment Skills"
        "Projects Projects"
        "Contact-Me Contact-Me";
}


.Bio { grid-area: Bio; }

.Employment { grid-area: Employment; }

.Skills { grid-area: Skills; }

.Projects { grid-area: Projects; }

.Contact-Me { grid-area: Contact-Me; }